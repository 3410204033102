<template lang="pug">
boxWrapper
  titleComponent(title="Receta paso a paso")
  .wrapper-steps
    .box-step(v-for="(step, index) in steps", :key="step._id")
      .step {{ index + 1 }}
      imageCloudinary.img(
        :image_url="step.image",
        width="540",
        v-if="step.image"
      )
      .description {{ step.description }}
</template>
<script>
import titleComponent from "./title-component.vue";
import boxWrapper from "./box-wrapper.vue";

import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "receipt-component",
  components: {
    titleComponent,
    boxWrapper,
  },
  setup() {
    const store = useStore();
    const steps = computed(() => store.getters["menuReceiptStore/steps"]);

    return {
      steps,
    };
  },
};
</script>
<style lang="scss" scoped>
.wrapper-steps {
  @apply py-4;
}
.box-step {
  @apply pl-4 ml-8 relative pb-2;

  & + .box-step {
    margin-top: 1rem;
  }

  .step {
    @apply absolute -left-8 top-0 w-5 h-5 flex items-center justify-center rounded-full bg-green-500 text-sm font-bold text-white;
  }

  .img {
    height: 220px;
    object-fit: cover;
    background-position: center center;
    @apply w-full rounded-lg overflow-hidden pb-4;
  }

  &::before {
    content: "";
    height: 100%;
    width: 4px;
    @apply bg-green-500 absolute left-0 rounded-full;
  }

  .description {
    @apply text-sm;
  }
}
</style>