<template lang="pug">
.content-box
  .content
    slot
</template>
<script>
export default {
  name: "box-wrapper",
};
</script>
<style lang="scss" scoped>
.content-box {
  @apply pb-8 px-4 -mt-6 relative z-20;
  .content {
    @apply border border-gray-100 rounded-lg p-4 bg-white shadow-md;
  }
}
</style>